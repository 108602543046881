var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-input-table-container"},[_c('el-form',{ref:"tableForm",attrs:{"model":_vm.tableFromData,"show-message":false}},[_c('el-table',{attrs:{"data":_vm.tableFromData.tableData}},[_c('el-table-column',{attrs:{"label":"日期","align":"center","width":"240px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{staticClass:"table-form-item",attrs:{"required":"","prop":'tableData.' + $index + '.date'}},[_c('el-date-picker',{attrs:{"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":"选择日期"},model:{value:(row.date),callback:function ($$v) {_vm.$set(row, "date", $$v)},expression:"row.date"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"模块","align":"center","width":"290px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{staticClass:"table-form-item",attrs:{"prop":'tableData.' + $index + '.modules',"rules":_vm.modulesRules}},[_c('el-cascader',{staticStyle:{"width":"260px"},attrs:{"filterable":"","clearable":"","options":_vm.moduleData},model:{value:(row.modules),callback:function ($$v) {_vm.$set(row, "modules", $$v)},expression:"row.modules"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"花费时间/分钟","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{staticClass:"table-form-item",attrs:{"prop":'tableData.' + $index + '.time',"rules":_vm.timeRules}},[_c('el-input',{attrs:{"width":"180px"},model:{value:(row.time),callback:function ($$v) {_vm.$set(row, "time", _vm._n($$v))},expression:"row.time"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"备注","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-form-item',{staticClass:"table-form-item",attrs:{"prop":'tableData.' + $index + '.mark'}},[_c('el-input',{model:{value:(row.mark),callback:function ($$v) {_vm.$set(row, "mark", _vm._n($$v))},expression:"row.mark"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{attrs:{"type":"danger","plain":"","size":"small"},on:{"click":function($event){return _vm.onDelectTabelRow(row, $index)}}},[_vm._v("删除")])]}}])})],1)],1),_c('el-button',{staticClass:"add-table-row-btn",attrs:{"type":"primary","plain":""},on:{"click":_vm.onAddTableRow}},[_vm._v("新增行")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }