<template>
  <div class="data-input-table-container">
    <el-form ref="tableForm" :model="tableFromData" :show-message="false">
      <el-table :data="tableFromData.tableData">
        <el-table-column label="日期" align="center" width="240px">
          <template slot-scope="{row, $index}">
            <el-form-item required :prop="'tableData.' + $index + '.date'" class="table-form-item">
              <el-date-picker v-model="row.date" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="模块" align="center" width="290px">
          <template slot-scope="{row, $index}">
            <el-form-item :prop="'tableData.' + $index + '.modules'" :rules="modulesRules" class="table-form-item">
              <el-cascader v-model="row.modules" filterable clearable :options="moduleData" style="width: 260px" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="花费时间/分钟" align="center" width="200px">
          <template slot-scope="{row, $index}">
            <el-form-item :prop="'tableData.' + $index + '.time'" :rules="timeRules" class="table-form-item">
              <el-input v-model.number="row.time" width="180px"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center">
          <template slot-scope="{row, $index}">
            <el-form-item :prop="'tableData.' + $index + '.mark'" class="table-form-item">
              <el-input v-model.number="row.mark"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px" align="center">
          <template slot-scope="{row, $index}">
            <el-button type="danger" plain size="small" @click="onDelectTabelRow(row, $index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button type="primary" plain class="add-table-row-btn" @click="onAddTableRow">新增行</el-button>
  </div>
</template>
<script>
// import moduleData from '@/data/moduleData'
import { getModules } from '@/api/modules.js'

export default {
  name: 'DataInputTable',
  props: [],
  data() {
    const validateModules = (rule, value, callback) => {
      console.log('validateModules')
      if (value && value.length > 0) {
        callback()
      } else {
        callback(new Error())
      }
    }
    return {
      tableFromData: {
        tableData: []
      },
      tableData: [],
      moduleData: [],
      timeRules: { required: true, type: 'number' },
      modulesRules: { validator: validateModules, trigger: 'change' }
    }
  },
  watch: {
    'tableFromData.tableData': {
      deep: true,
      handler: function(val) {
        this.$emit('update', val)
      }
    }
  },
  created() {
    this.getModulesApi()
  },
  methods: {
    onAddTableRow() {
      // this.$set()
      this.tableFromData.tableData.push({
        date: '',
        modules: [],
        time: '',
        mark: ''
      })
    },
    onDelectTabelRow(row, index) {
      this.tableFromData.tableData.splice(index, 1)
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.tableForm.validate((flag, name) => {
          resolve(flag)
        })
      })
    },
    getModulesApi() {
      getModules().then(res => {
        this.moduleData = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table-form-item{
    margin-bottom: 0;
}
.add-table-row-btn{
    margin-top: 20px;
}
</style>
