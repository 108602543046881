<template>
  <el-form :model="excelOptions" label-suffix="：" size="medium" inline>
    <el-form-item prop="filename" label="文件名">
      <el-input v-model="excelOptions.filename" placeholder="请输入文件名称" prefix-icon="el-icon-document" />
    </el-form-item>
    <el-form-item prop="autoWidth" label="表格宽度自适应">
      <el-switch v-model="excelOptions.autoWidth" />
    </el-form-item>
    <el-form-item prop="bookType" label="导出文件类型">
      <el-select v-model="excelOptions.bookType" style="width:120px;">
        <el-option
          v-for="item in options"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button :loading="downloadLoading" type="primary" size="medium" icon="el-icon-document" @click="handleDownload">
        一键导出Excel
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'ExcelOptions',
  props: {
    btnLoading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      excelOptions: {
        filename: '人效统计',
        autoWidth: true,
        bookType: 'xlsx'
      },
      options: ['xlsx', 'csv', 'txt']
    }
  },
  computed: {
    downloadLoading() {
      return this.btnLoading
    }
  },
  methods: {
    handleDownload() {
      this.$emit('handle-download', this.excelOptions)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-form-item{
    margin-right: 30px;
}
</style>
