import axios from 'axios'
import { Message } from 'element-ui'
// import log from '@/utils/log'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * 您还可以通过HTTP状态代码判断状态
   */
  response => {
    const res = response.data
    // if (res.code !== 200) {
    //     if (res.code === 412) {
    //         Message({
    //             message: res.message || '请求参数错误',
    //             type: 'error',
    //             showClose: true,
    //             duration: 1500
    //         })
    //     } else {
    //         Message({
    //             message: res.message || '系统异常',
    //             type: 'error',
    //             showClose: true,
    //             duration: 1500
    //         })
    //     }
    //     return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //     return res
    // }
    // 不涉及后端，code判断去掉
    return res
  },
  error => {
    if (error.message.includes('timeout')) {
      Message({
        message: '请求超时，请联系管理员或稍后再试',
        type: 'error',
        showClose: true,
        duration: 1500
      })
      return Promise.reject(error)
    }
    // log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      showClose: true,
      duration: 1500
    })
    return Promise.reject(error)
  }
)

export default service
