<template>
  <div class="app-container">
    <excel-options :btn-loading="downloadLoading" @handle-download="beforeDownload" />
    <data-input-table ref="dataInputTable" @update="onTableUpdate" />
  </div>
</template>

<script>
import ExcelOptions from './components/ExcelOptions'
import DataInputTable from './components/DataInputTable'

export default {
  name: 'ExportExcel',
  components: {
    ExcelOptions,
    DataInputTable
  },
  data() {
    return {
      options: ['xlsx', 'csv', 'txt'],
      tableData: [],
      downloadLoading: false
    }
  },
  created() {

  },
  methods: {
    beforeDownload(excelOptions) {
      if (!this.tableData || this.tableData.length < 1) {
        this.$message.error('没有可导出数据！')
        return
      }
      this.$refs.dataInputTable.validateForm().then(res => {
        if (res) {
          this.handleDownload(excelOptions)
        } else {
          this.$message.error('验证不通过，有字段为空！')
        }
      })
    },
    handleDownload(excelOptions) {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['日期', '一级模块', '二级模块', '三级模块', '花费时间/分钟', '备注']
        const list = this.tableData.map(item => {
          return [
            item.date || '',
            item.modules[0] || '',
            item.modules[1] || '',
            item.modules[2] || '',
            item.time || '',
            item.mark || ''
          ]
        })
        excel.export_json_to_excel({
          header: tHeader,
          data: list,
          filename: excelOptions.filename,
          autoWidth: excelOptions.autoWidth,
          bookType: excelOptions.bookType
        })
        this.downloadLoading = false
      })
    },

    onTableUpdate(tableData) {
      this.tableData = tableData
    }
  }
}
</script>

<style lang="scss" scoped>
.export-btn {
    margin:0 0 20px 20px;
}
</style>
